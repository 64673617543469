var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "c-section" },
      [
        _c("h1", { staticClass: "c-title2 c-title2--center c-title2--mb24" }, [
          _vm._v(" " + _vm._s(_vm.WORDS.LINK_LINE.TITLE) + " "),
        ]),
        _c("div", { staticClass: "link-line-logo" }, [
          _c("img", {
            attrs: {
              src: "/assets/img/logo_ucc01.svg",
              alt: _vm.WORDS.UCC + " " + _vm.WORDS.UCC_CATCHPHRASE,
            },
          }),
        ]),
        _c("p", { staticClass: "link-line-lead" }, [
          _vm._v(" " + _vm._s(_vm.WORDS.LINK_LINE.DESCRIPTION) + " "),
        ]),
        _c("div", { staticClass: "link-line-incentive" }, [
          _c("p", { staticClass: "link-line-incentive-title" }, [
            _c(
              "span",
              { staticClass: "link-line-incentive-title__left" },
              [
                _c("Icon", {
                  attrs: { name: "bubbleLeft", width: "35", height: "45" },
                }),
              ],
              1
            ),
            _c("span", { staticClass: "link-line-incentive-title__text" }, [
              _vm._v(_vm._s(_vm.WORDS.OFFICIAL_LINE) + "と"),
              _c("br"),
              _vm._v(
                " " +
                  _vm._s(_vm.WORDS.LINE_ADD_FRIEND) +
                  " & " +
                  _vm._s(_vm.WORDS.LINE_LINK_ID) +
                  "でもらえる"
              ),
            ]),
            _c(
              "span",
              { staticClass: "link-line-incentive-title__right" },
              [
                _c("Icon", {
                  attrs: { name: "bubbleRight", width: "35", height: "45" },
                }),
              ],
              1
            ),
          ]),
          _c(
            "ul",
            { staticClass: "link-line-incentive-list" },
            [
              _c("IncentiveListItem", [
                _vm._v(" " + _vm._s(_vm.mile) + _vm._s(_vm.WORDS.MILE) + " "),
              ]),
              _vm.point
                ? _c("IncentiveListItem", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.WORDS.OFFICIAL) +
                        _vm._s(_vm.WORDS.ONLINESTORE)
                    ),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(_vm.WORDS.POINT) +
                        _vm._s(_vm.point) +
                        _vm._s(_vm.WORDS.POINT) +
                        " "
                    ),
                  ])
                : _vm._e(),
              _vm.coupon
                ? _c("IncentiveListItem", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.WORDS.OFFICIAL) +
                        _vm._s(_vm.WORDS.ONLINESTORE)
                    ),
                    _c("br"),
                    _vm._v(" 限定" + _vm._s(_vm.WORDS.COUPON) + " "),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
        _c(
          "ButtonLineLogin",
          {
            attrs: {
              href: _vm.cosmbiWebLoginUrl,
              fluid: "",
              disabled: _vm.loading,
            },
          },
          [_vm.loading ? [_vm._v(_vm._s(_vm.WORDS.PROCESSING))] : _vm._e()],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }