<template>
  <div>
    <div class="c-section">
      <h1 class="c-title2 c-title2--center c-title2--mb24">
        {{ WORDS.LINK_LINE.TITLE }}
      </h1>

      <div class="link-line-logo">
        <img
          src="/assets/img/logo_ucc01.svg"
          :alt="`${WORDS.UCC} ${WORDS.UCC_CATCHPHRASE}`" />
      </div>

      <p class="link-line-lead">
        {{ WORDS.LINK_LINE.DESCRIPTION }}
      </p>

      <div class="link-line-incentive">
        <p class="link-line-incentive-title">
          <span class="link-line-incentive-title__left">
            <Icon name="bubbleLeft" width="35" height="45" />
          </span>
          <span class="link-line-incentive-title__text">{{ WORDS.OFFICIAL_LINE }}と<br />
            {{ WORDS.LINE_ADD_FRIEND }} &
            {{ WORDS.LINE_LINK_ID }}でもらえる</span>
          <span class="link-line-incentive-title__right">
            <Icon name="bubbleRight" width="35" height="45" />
          </span>
        </p>

        <ul class="link-line-incentive-list">
          <IncentiveListItem> {{ mile }}{{ WORDS.MILE }} </IncentiveListItem>
          <IncentiveListItem v-if="point">
            {{ WORDS.OFFICIAL }}{{ WORDS.ONLINESTORE }}<br />
            {{ WORDS.POINT }}{{ point }}{{ WORDS.POINT }}
          </IncentiveListItem>
          <IncentiveListItem v-if="coupon">
            {{ WORDS.OFFICIAL }}{{ WORDS.ONLINESTORE }}<br />
            限定{{ WORDS.COUPON }}
          </IncentiveListItem>
        </ul>
      </div>

      <ButtonLineLogin :href="cosmbiWebLoginUrl" fluid :disabled="loading">
        <template v-if="loading">{{ WORDS.PROCESSING }}</template>
      </ButtonLineLogin>
    </div>
  </div>
</template>

<script>
import { WORDS } from '@/constants/words';
import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  components: {
    IncentiveListItem: () =>
      import('@/components/link/line/IncentiveListItem.vue')
  },

  props: {
    mile: {
      type: Number,
      default: 0
    },

    coupon: {
      type: Boolean,
      default: false
    },

    point: {
      type: Number,
      default: 0
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  setup: () => {
    const cosmbiWebLoginUrl = computed(
      () => process.env.VUE_APP_COMSBI_WEBLOGIN_URL
    );
    return {
      WORDS,
      cosmbiWebLoginUrl
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.link-line-logo {
  text-align: center;
  margin-bottom: 2.4rem;

  > img {
    width: 96px;
  }
}

.link-line-lead {
  margin-bottom: 32px;
  font-size: 13px;
  line-height: 1.5;
}

.link-line-incentive-title {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 20px;
  &__left,
  &__right {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-shrink: 0;
    line-height: 1;
    > svg {
      margin-bottom: -3px;
    }
  }
  &__text {
    display: block;
    padding: 0 14px;
    font-size: 16px;
    line-height: 1.4;
    font-weight: bold;
    text-align: center;
  }
}
</style>
